import * as React from 'react';

import { Layout } from '../components/Layout';
import { SEO } from '../components/SEO';

const ContactSuccessPage: React.FC = () => (
	<Layout>
		<SEO title='Thank you' />
		<div id='contact-request' className='bg-white pt-24'>
			<section className='container max-w-5xl mx-auto mx-4'>
				<h1 className='w-full my-2 text-4xl uppercase font-bold leading-tight text-center text-gray font-header'>
					Thank you!
        </h1>
        <div className='flex flex-wrap'>
          <div className='w-full p-4'>
            <p className='text-gray-light text-center text-xl'>Your contact request has been received and we will get in touch shortly.</p>
          </div>
        </div>
			</section>
		</div>
	</Layout>
);

export default ContactSuccessPage;
